import { useQuery } from 'react-query'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { getRoute } from '../../../utils/api/routes/regions'

export const useRoute = (route: string) => {
    const { regionNumber } = useAppSelector(state => state.directions)

    const { data, ...query } = useQuery(
        ['route', regionNumber, route],
        () => getRoute(+(regionNumber || 2), route),
        {
            enabled: regionNumber !== null,
        },
    )

    return {
        stops: data?.data.stops || [],
        r_stops: data?.data.r_stops || [],
        lines: data?.data.lines || [],
        r_lines: data?.data.r_lines || [],
        position: data?.data.stops[0].position,
        ...query,
    }
}
