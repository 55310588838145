import { useCallback, useState } from 'react'

export const useDisclosure = (initialState = false) => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState)

    const toggle = useCallback(() => setIsOpen(state => !state), [])
    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    return { isOpen, toggle, open, close }
}
