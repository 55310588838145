import { useInfiniteQuery } from 'react-query'

import { useAppSelector } from '../../../hooks/useAppSelector'
import { getAllTransactions } from '../../../utils/api/routes/transactions'
import { GetAllTransactionsBody } from '../../../utils/api/routes/transactions/types'

export const useInfiniteTransactions = (params: Omit<GetAllTransactionsBody, 'page'>) => {
    const cardInfo = useAppSelector(state => state.user.card)

    const { data, ...query } = useInfiniteQuery(
        ['transaction', params],
        ({ pageParam = 1 }) => getAllTransactions({ ...params, page: pageParam }),
        {
            enabled: !!cardInfo,
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
            staleTime: 0,
            cacheTime: 0,
            refetchInterval: 5000,
            getNextPageParam: lastPage => lastPage?.data.next,
        },
    )

    return {
        transactions: data?.pages?.map(page => page.data.data)?.flat(2) || [],
        ...query,
    }
}
