import { Paper, styled, Button, OutlinedInput } from '@mui/material'

export const Root = styled('div')({
    backgroundColor: '#F6F6F6',
    width: '100vw',
    textAlign: 'center',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
})

export const Logo = styled('img')(props => ({
    paddingBottom: '5%',
    maxWidth: '279px',
    [props.theme.breakpoints.down('md')]: {
        maxWidth: '140px',
    },
}))

export const Wrapper = styled(Paper)(props => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '756px',
    width: '100%',
    padding: '30px 50px',
    borderRadius: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    [props.theme.breakpoints.down('md')]: {
        padding: '30px 25px',
    },
}))

export const Form = styled('form')(props => ({
    display: 'flex',
    margin: '0 auto',
    width: '100%',
    minWidth: 400,
    flexDirection: 'column',
    gap: 15,
    [props.theme.breakpoints.down('md')]: {
        minWidth: 'auto',
    },
}))

export const Title = styled('h2')(props => ({
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '25px',
    lineHeight: '0',
    textAlign: 'center',
    textTransform: 'uppercase',
    [props.theme.breakpoints.down('md')]: {
        fontSize: 18,
    },
}))

export const AuthFormInput = styled(OutlinedInput)(props => ({
    width: '100%',
    borderRadius: 10,
    fontWeight: '500',
    [props.theme.breakpoints.down('md')]: {
        '& .MuiOutlinedInput-input': {
            padding: '10px',
        },
    },
}))

export const AuthButton = styled(Button)(props => ({
    display: 'block',
    margin: '0 auto',
    width: '100%',
    borderRadius: '10px',
    fontSize: '15px',
    padding: '15px !important',
    fontWeight: '600',
    fontFamily: 'Montserrat, sans-serif',
    [props.theme.breakpoints.down('md')]: {
        padding: '12.5px 7.5px !important',
    },
}))
