import { combineReducers } from '@reduxjs/toolkit'
import directionsReducer from './directions'
import usersReducer from '../reducers/user/index'
import transactionReducer from './transactions'

export const rootReducer = combineReducers({
    directions: directionsReducer,
    user: usersReducer,
    transactions: transactionReducer,
})
