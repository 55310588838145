import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentTitle } from '../../components/DocumentTitle'
import { Buses } from './Buses'

import { Header, Title, NotFoundButton } from './styles'

const Directions: FC = () => {
    const { t } = useTranslation()

    return (
        <DocumentTitle title={t('titles.directions')}>
            <Header>
                <Title>{t('directions.title')}</Title>
                <NotFoundButton variant="outlined">
                    {t('directions.buttons.notFound')}
                </NotFoundButton>
            </Header>
            <Buses />
        </DocumentTitle>
    )
}

export default Directions
