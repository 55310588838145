import { FC, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Cookie from 'js-cookie'
import { useDispatch } from 'react-redux'

import { useAppSelector } from './hooks/useAppSelector'

import Cards from './pages/Cards'
import Directions from './pages/Routes'
import Details from './pages/Details'
import Settings from './pages/Settings'
import Instructions from './pages/Instructions'
import BusRoute from './pages/Route'
import Login from './pages/Auth/Login'
import Registration from './pages/Auth/Registration'
import { AuthPageWrapper } from './pages/Auth'
import { PageWrapper } from './components/PageWrapper'
import { getAllProfile, setLogged } from './store/reducers/user'
import { setAuthToken } from './utils/api'
import { Layout } from './components/Layout'

const App: FC = () => {
    const dispatch = useDispatch()
    const isLogged = useAppSelector(state => state.user.isLogged)

    if (Cookie.get('@alempay/access_token')) {
        setAuthToken(Cookie.get('@alempay/access_token') as string)
        dispatch(setLogged(true))
    }

    useEffect(() => {
        if (isLogged) {
            dispatch(getAllProfile())
        }
    }, [isLogged])

    if (isLogged) {
        return (
            <Layout>
                <Routes>
                    <Route path="/" element={<PageWrapper />}>
                        <Route index element={<Cards />} />
                        <Route path="routes" element={<Directions />} />
                        <Route path="routes/:route" element={<BusRoute />} />
                        <Route path="details" element={<Details />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="instructions" element={<Instructions />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Route>
                </Routes>
            </Layout>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<AuthPageWrapper />}>
                <Route index element={<Login />} />
                <Route path="register" element={<Registration />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    )
}

export default App
