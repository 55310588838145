import { HTTP } from '../..'
import { GetAllTransactions, GetAllTransactionsBody } from './types'
import i18next from 'i18next'

export const getAllTransactions = (body: GetAllTransactionsBody) => {
    return HTTP.post<GetAllTransactions>('/transactions/all/', body)
}

export const refillTransaction = (data: { pay_id: string; amount: string }) => {
    const lang = i18next.language
    return HTTP.post<{ status: 'error' | 'success'; message: string }>(
        '/transactions/refill/',
        {
            ops_type: 'default',
            ...data,
        },
        { params: { lang } },
    )
}
