import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgIconComponent, CreditCard, Schedule, Route, Help, Settings } from '@mui/icons-material'

export interface Link {
    label: string
    path: string
    icon: SvgIconComponent
}

export const useLinks = () => {
    const { t } = useTranslation()

    const links = useMemo<Link[]>(
        () => [
            {
                label: t('sidebar.cards'),
                path: '/',
                icon: CreditCard,
            },
            {
                label: t('sidebar.directions'),
                path: '/routes',
                icon: Route,
            },
            {
                label: t('sidebar.details'),
                path: '/details',
                icon: Schedule,
            },
            {
                label: t('sidebar.instructions'),
                path: '/instructions',
                icon: Help,
            },
            {
                label: t('sidebar.settings'),
                path: '/settings',
                icon: Settings,
            },
        ],
        [],
    )

    return links
}
