import { useQuery } from 'react-query'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { getPosition } from '../../../../utils/api/routes/position'

export const useBuses = (route: number | string) => {
    const { regionNumber } = useAppSelector(state => state.directions)

    const { data, ...query } = useQuery(
        ['buses', regionNumber, route],
        () => getPosition(+(regionNumber || 0), route),
        {
            enabled: regionNumber !== null,
            refetchInterval: 1000,
        },
    )

    return {
        busPositions: data?.data.pos || [],
        ...query,
    }
}
