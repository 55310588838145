import { HTTP } from '../..'
import i18next from 'i18next'

export interface GetPositionResponse {
    pos: {
        id: number
        num: string
        dir: number
        lat: number
        lon: number
    }[]
}

export const getPosition = (region: number, route: number | string) => {
    const lang = i18next.language
    return HTTP.get<GetPositionResponse>('/service-info/position/', {
        params: { region, route, lang },
    })
}
