import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HTTP, setAuthToken } from '../../../utils/api'
import { LoginPayload, State, User } from './types'
import Cookie from 'js-cookie'
import { CardMetaData } from '../../../components/Card'
import { setRegion } from '../directions'

const initialState: State = {
    profile: null,
    isLogged: false,
    card: null,
    cards: [],
}

const userReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setProfile: (state, action: PayloadAction<State['profile']>) => {
            state.profile = action.payload
        },
        setLogged: (state, action: PayloadAction<boolean>) => {
            state.isLogged = action.payload
        },
        setActiveCard: (state, action: PayloadAction<State['card']>) => {
            state.card = action.payload
        },
        setCards: (state, action: PayloadAction<State['cards']>) => {
            state.cards = action.payload
        },
    },
})

export const getAllProfile = createAsyncThunk<object, undefined>(
    'user/getAllProfile',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await HTTP.get<User>('/user/me/')
            if (res.status === 200) {
                dispatch(setProfile(res.data))
                dispatch(setRegion(res.data.region))
                dispatch(getUserCards())
            }
            return res
        } catch (e) {
            dispatch(setLogged(false))
            return rejectWithValue(e)
        }
    },
)

export const getUserCards = createAsyncThunk<object, undefined>(
    'user/getAllProfile',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await HTTP.get<{ cards: CardMetaData[] }>('/payment/cards/')
            if (res.status === 200) {
                dispatch(setCards(res.data.cards))
                dispatch(setActiveCard(res.data.cards[0]))
            }
            return res
        } catch (e) {
            return rejectWithValue(e)
        }
    },
)
export const login = createAsyncThunk<object, LoginPayload>(
    'user/login',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await HTTP.post<{ access_token: string }>('/user/auth/', data)
            if (res.data.access_token) {
                Cookie.set('@alempay/access_token', res.data.access_token)
                setAuthToken(res.data.access_token)
                dispatch(getAllProfile())
                dispatch(setLogged(true))
            }
            return true
        } catch (e) {
            return rejectWithValue(e)
        }
    },
)

export const logOut = createAsyncThunk<object, undefined>(
    'user/logout',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await HTTP.get('/user/logout/')
            if (res.status === 204) {
                dispatch(setProfile(null))
                Cookie.remove('@alempay/access_token')
                setAuthToken('')
                dispatch(setLogged(false))
            }
            return res
        } catch (e) {
            return rejectWithValue(e)
        }
    },
)

export const { setProfile, setActiveCard, setCards, setLogged } = userReducer.actions
export default userReducer.reducer
