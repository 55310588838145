import { styled, Link } from '@mui/material'

export const Title = styled('h1')(({ theme }) => ({
    margin: 0,
    fontSize: '28px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
    },
}))

export const Description = styled('span')({
    display: 'block',
    fontSize: '18px',
    fontWeight: '500',
})

export const Wrapper = styled('div')(({ theme }) => ({
    paddingTop: '55px',
    '& span': {
        fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
        paddingTop: '30px',
        '& span': {
            fontSize: '15px',
        },
    },
}))

export const InstructionsItem = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    paddingLeft: '45px',
    paddingRight: '20px',
    paddingTop: '15px',
    paddingBottom: '15px',
    boxShadow: '0px 0px 30px rgba(51, 64, 79, 0.05)',
    '&:last-child': {
        marginBottom: '0',
    },
    [theme.breakpoints.down('lg')]: {
        paddingLeft: '20px',
        paddingRight: '10px',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
}))

export const InstructionsButton = styled(Link)(({ theme }) => ({
    marginLeft: '20px',

    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '12px',
    paddingBottom: '10px',

    backgroundColor: '#3CC67B',
    borderRadius: '10px',

    lineHeight: '100%',

    color: '#FFF',

    '& svg': {
        fontSize: '25px',
    },

    [theme.breakpoints.down('lg')]: {
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '6px',
        paddingBottom: '4px',
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
    },
}))
