import { Dispatch, FC, SetStateAction } from 'react'
import { Box, IconButton, Theme, Tooltip, useMediaQuery, Modal } from '@mui/material'
import { ImportExport } from '@mui/icons-material'

import { Direction } from '../types'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { Body } from './Body'

export interface PanelProps {
    route: string
    direction: Direction
    setDirection: Dispatch<SetStateAction<Direction>>
}

export const Panel: FC<PanelProps> = props => {
    const { isOpen, open, close } = useDisclosure()
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    if (isMobile) {
        return (
            <>
                <Tooltip title="Схема маршрута">
                    <IconButton
                        onClick={open}
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 20,
                            zIndex: 2,
                            bgcolor: 'white',
                            '&:hover': { bgcolor: 'white' },
                        }}
                    >
                        <ImportExport />
                    </IconButton>
                </Tooltip>
                <Modal component="div" open={isOpen} onClose={close}>
                    <Box
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            maxWidth: 600,
                            minWidth: 400,
                            height: '80vh',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <Body {...props} />
                    </Box>
                </Modal>
            </>
        )
    }

    return (
        <Box
            border="2px solid #C4C4C4"
            zIndex={2}
            bgcolor="white"
            width="400px"
            overflow="auto"
            maxHeight="50vh"
            position="absolute"
            top={10}
            right={20}
            borderRadius="6px"
        >
            <Body {...props} />
        </Box>
    )
}
