import React, { useState } from 'react'
import { Modal, Typography, ModalProps, TextField } from '@mui/material'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'

import { Centered, SubmitButton } from '../AddCardModal/styles'

import { refillTransaction } from '../../utils/api/routes/transactions'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useTranslation } from 'react-i18next'

export const RefillModal: React.FC<Omit<ModalProps, 'children'>> = props => {
    const [amount, setAmount] = useState('0')
    const card = useAppSelector(state => state.user.card?.card)
    const { t } = useTranslation()
    const { mutate } = useMutation(refillTransaction, {
        onSuccess(res) {
            toast[res.data.status](res.data.message)
            if (res.data.status === 'success') props.onClose?.({}, 'backdropClick')
        },
    })

    const onSubmit = () => {
        if (!Number(amount) || Number(amount) <= 0) {
            toast.error(t('refill.error'))
        } else {
            mutate({ pay_id: card as string, amount })
        }
    }

    return (
        <Modal {...props}>
            <Centered>
                <Typography variant="h5" fontWeight={600}>
                    {t('refill.title')}
                </Typography>
                <TextField
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    fullWidth
                    size="small"
                    placeholder={t('refill.amount')}
                    sx={{ mt: 2 }}
                />
                <Typography mt={2} fontSize={14} textAlign="center" color="#A9A9A9">
                    {t('refill.desc')}
                </Typography>
                <SubmitButton onClick={onSubmit} size="small">
                    {t('refill.action')}
                </SubmitButton>
            </Centered>
        </Modal>
    )
}
