import { Box, styled } from '@mui/material'
import { AuthButton } from '../../pages/Auth/styles'

export const Centered = styled(Box)(theme => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    background: 'white',
    borderRadius: 20,
    padding: '35px 40px',
    textAlign: 'center',
    [theme.theme.breakpoints.down('sm')]: {
        width: 300,
    },
}))

export const SubmitButton = styled(AuthButton)({
    background:
        ' linear-gradient(256.77deg, #8A8FFF 26.54%, #AF8AFF 59.51%), linear-gradient(256.77deg, #8A8FFF 26.54%, #AF8AFF 59.51%)',
    fontSize: '14px',
    boxShadow: 'none',
    color: 'white',
    width: '100%',
    marginTop: 10,
    '&:hover': {
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
    },
})
