import {
    styled,
    List,
    ListItem,
    Avatar,
    ListSubheader,
    ListItemText,
    Popover,
    ListItemTextProps,
} from '@mui/material'
import { CalendarPicker } from '@mui/x-date-pickers'

export const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

export const HeaderTitle = styled('h1')(({ theme }) => ({
    margin: 0,
}))

export const TransactionsHistory = styled('div')(({ theme }) => ({
    borderRadius: '5px',

    textTransform: 'none',
}))

export const TransactionBox = styled(List)(({ theme }) => ({
    paddingTop: '40px',
    paddingBottom: '0',
}))

export const TransactionDay = styled(ListSubheader)(({ theme }) => ({
    padding: '10px 5px',

    fontSize: '18px',
    lineHeight: '24px',

    color: '#A3A3A3',

    [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
        lineHeight: '14px',
    },
}))

export const TransactionItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: '30px',
    paddingRight: '35px',
    paddingTop: '15px',
    paddingBottom: '15px',
    cursor: 'pointer',
    backgroundColor: '#F9F9F9',
    borderRadius: '5px',

    marginBottom: '15px',

    '&:last-child': {
        marginBottom: 0,
    },

    '& div': {
        marginTop: 0,
        marginBottom: 0,

        '&:last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
            '& span': {
                fontSize: '20px',
            },
        },
    },

    [theme.breakpoints.down('sm')]: {
        paddingLeft: '20px',
        paddingRight: '20px',

        '& div:last-child': {
            '& span': {
                fontSize: '15px',
            },
        },
    },
}))

export const TransactionLogo = styled(Avatar)(({ theme }) => ({
    width: '50px',
    height: '50px',

    marginRight: '35px',

    [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '30px',
        display: 'none',
        marginRight: '15px',
    },
}))

interface TransactionInfoProps extends ListItemTextProps {
    type?: 'withdrawals' | 'deposit'
}

const getTransactionColor = (type: TransactionInfoProps['type']) => {
    switch (type) {
        // case "withdrawals":
        //     return '#e74c3c'
        case 'deposit':
            return 'rgba(60, 198, 123, 1)'
        default:
            return '#000'
    }
}

export const TransactionInfo = styled(ListItemText)<TransactionInfoProps>(({ theme, type }) => ({
    '& span': {
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '100%',

        paddingBottom: '3px',

        color: getTransactionColor(type),
    },

    '& p': {
        fontSize: '10px',

        color: '#A1A6AA',
    },

    [theme.breakpoints.down('sm')]: {
        '& span': {
            fontSize: '15px',
        },
    },
}))

export const TransactionNum = styled('div')(theme => ({
    color: 'silver',
    fontSize: 13,
    float: 'left',
    textAlign: 'left',
}))

export const CalendarPopover = styled(Popover)(({ theme }) => ({
    marginTop: '5px',

    backgroundColor: 'transparent',
}))

export const CalendarContainer = styled('div')(({ theme }) => ({}))

export const Calendar = styled(CalendarPicker)(({ theme }) => ({
    border: '1px solid #BC9BFE',
    borderRadius: '5px',

    backgroundColor: '#FFF',
}))
