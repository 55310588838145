import { createTheme } from '@mui/material'
import { deepPurple } from '@mui/material/colors'

export const theme = createTheme({
    palette: {
        primary: {
            light: deepPurple['50'],
            main: deepPurple['A100'],
            dark: deepPurple['400'],
            contrastText: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableTouchRipple: true,
            },
        },
    },
})
