import { FC } from 'react'
import { Skeleton, Avatar } from '@mui/material'

import { Container, Link } from '../Direction/styles'

export const LoadingView: FC = () => {
    return (
        <>
            {Array.from({ length: 3 }).map((_, index) => (
                <Link to="">
                    <Container key={index}>
                        <Skeleton variant="circular">
                            <Avatar />
                        </Skeleton>
                        <Skeleton variant="text" animation="wave" width="50%" />
                    </Container>
                </Link>
            ))}
        </>
    )
}
