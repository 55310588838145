import { useMemo, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, Theme, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { AccessTime, AddCircleOutline, Lock, LockOpen, Payments } from '@mui/icons-material'

import {
    CardActionItem,
    CardActionItemProps,
    CardActions,
    CardActionText,
    CardBlock,
    CardContainer,
} from './styles'
import { Card } from '../../../components/Card'
import { Transactions } from '../../../components/Transactions'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { AddCardModal } from '../../../components/AddCardModal'
import { MockModal } from '../../../components/MockModal'
import { getAllTransactions } from '../../../utils/api/routes/transactions'
import { RefillModal } from '../../../components/RefillModal'

interface CardActionProps extends CardActionItemProps {
    gridArea: string
    text: string
    icon: JSX.Element
    onActionClick?: () => void
}

const CardAction: FC<CardActionProps> = ({ gridArea, text, icon, onActionClick, disabled }) => {
    return (
        <CardActionItem disabled={disabled} onClick={onActionClick} sx={{ gridArea }}>
            {icon}
            <CardActionText>{text}</CardActionText>
        </CardActionItem>
    )
}

export const MyCard: FC = () => {
    const { t } = useTranslation()
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const {
        isOpen: isAddCardModalOpen,
        open: openAddCardModal,
        close: closeAddCardModal,
    } = useDisclosure()
    const { isOpen: isBlockCardOpen, open: openBlockCard, close: closeBlockCard } = useDisclosure()
    const { isOpen: isRefillOpen, open: openRefill, close: closeRefill } = useDisclosure()
    const {
        isOpen: isUnblockCard,
        open: openUnblockCard,
        close: closeUnblockCard,
    } = useDisclosure()
    const navigate = useNavigate()
    const isLessDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
    const cardInfo = useAppSelector(state => state.user.card)
    const { regionNumber } = useAppSelector(state => state.directions)
    const cardActions: CardActionProps[] = useMemo(
        () => [
            {
                gridArea: 'add',
                text: t('cards.cardActions.add'),
                icon: <AddCircleOutline fontSize="large" />,
                onActionClick() {
                    openRefill()
                },
            },
            {
                gridArea: 'history',
                text: t('cards.cardActions.history'),
                icon: <AccessTime fontSize="large" />,
                onActionClick() {
                    navigate('/details')
                },
            },
            {
                gridArea: 'activate',
                text: t('cards.cardActions.activate'),
                icon: <Payments fontSize="large" />,
                onActionClick() {
                    openAddCardModal()
                },
            },
            {
                gridArea: 'block',
                text: t('cards.cardActions.block'),
                icon: <Lock fontSize="large" />,
                onActionClick() {
                    openBlockCard()
                },
            },
            {
                gridArea: 'unblock',
                text: isMobile
                    ? t('cards.cardActions.shortUnblock')
                    : t('cards.cardActions.unblock'),
                icon: <LockOpen fontSize="large" />,
                onActionClick() {
                    openUnblockCard()
                },
            },
        ],
        [isMobile, t, cardInfo],
    )
    const { isLoading, data } = useQuery(
        ['transactions', regionNumber],
        () =>
            getAllTransactions({
                page: 1,
                card: cardInfo?.card as string,
                region: `${regionNumber}`,
            }),
        {
            enabled: !!cardInfo,
            refetchOnWindowFocus: false,
        },
    )

    return (
        <>
            <AddCardModal
                open={isAddCardModalOpen}
                onClose={closeAddCardModal}
                onSubmit={closeAddCardModal}
            />
            <RefillModal open={isRefillOpen} onClose={closeRefill} />
            <MockModal
                title={t('card.block.title')}
                sub={t('card.block.desc')}
                description={t('card.block.sub')}
                button={t('card.accept')}
                open={isBlockCardOpen}
                onClose={closeBlockCard}
            />
            <MockModal
                title={t('card.unblock.title')}
                description={t('card.unblock.desc')}
                sub={t('card.unblock.sub')}
                button={t('card.accept')}
                open={isUnblockCard}
                onClose={closeUnblockCard}
            />
            <CardContainer>
                {cardInfo && <Card {...cardInfo} />}
                <CardBlock>
                    <CardActions>
                        {cardActions.map(props => (
                            <CardAction key={props.gridArea} {...props} />
                        ))}
                    </CardActions>
                </CardBlock>
            </CardContainer>
            <Box marginTop="60px">
                <Transactions
                    data={data?.data?.data || []}
                    titleText={isLessDesktop ? t('details.shortTitle') : t('details.title')}
                    withFilter={false}
                    titleSize="large"
                    isLoading={isLoading}
                />
            </Box>
        </>
    )
}
