import { HTTP } from '../../index'

export interface Instruction {
    id: number
    title: string
    src_url: string
}

export const getInstructions = async () =>
    await HTTP.get<Instruction[]>(`/instructions/`).then(response => response.data)
