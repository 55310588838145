import React from 'react'
import { DirectionsBus } from '@mui/icons-material'
import { Box, Typography, Button } from '@mui/material'
import { PanelProps } from '..'
import { BusRoute } from '../BusRoute'
import { useRoute } from '../../utils/useRoute'
import { useTranslation } from 'react-i18next'

export const Body: React.FC<PanelProps> = ({ route, setDirection, direction }) => {
    const { stops, r_stops } = useRoute(route)
    const { t } = useTranslation()

    return (
        <>
            <Box
                position="sticky"
                top={0}
                bgcolor="white"
                p={2}
                zIndex={1000}
                display="flex"
                alignItems="center"
                gap="10px"
            >
                <DirectionsBus />
                <Typography fontSize="18px" fontWeight={500}>
                    {t('map.route')} - {route}
                </Typography>
            </Box>
            <Box px={2}>
                <Box display="flex" alignItems="center" gap="10px">
                    <Button
                        fullWidth
                        onClick={() => setDirection('straight')}
                        variant={direction === 'straight' ? 'contained' : 'outlined'}
                    >
                        {t('map.direction.forward')}
                    </Button>
                    <Button
                        fullWidth
                        onClick={() => setDirection('reverse')}
                        variant={direction === 'reverse' ? 'contained' : 'outlined'}
                    >
                        {t('map.direction.back')}
                    </Button>
                </Box>
                {direction === 'reverse' ? (
                    <BusRoute reverse stops={r_stops} />
                ) : (
                    <BusRoute stops={stops} />
                )}
            </Box>
        </>
    )
}
