import { styled, Button } from '@mui/material'

export const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        paddingBottom: '25px',
    },
}))

export const Title = styled('h1')(({ theme }) => ({
    margin: 0,

    fontSize: '28px',

    [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
    },
}))

export const NotFoundButton = styled(Button)(({ theme }) => ({
    borderColor: '#000',

    textTransform: 'none',
    color: '#000',

    [theme.breakpoints.down('sm')]: {
        maxWidth: '150px',

        fontSize: '15px',
        lineHeight: '18px',
    },
}))
