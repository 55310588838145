import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from './types'

const initialState = { regionNumber: null } as State

const directionsReducer = createSlice({
    name: 'directions',
    initialState,
    reducers: {
        setRegion(state, { payload }: PayloadAction<number | string>) {
            state.regionNumber = payload || 2
        },
    },
})

export const { setRegion } = directionsReducer.actions
export default directionsReducer.reducer
