import axios from 'axios'
import Cookies from 'js-cookie'

export const HTTP = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
})

HTTP.interceptors.response.use(
    res => res,
    err => {
        if (err?.response?.status === 401) {
            Cookies.remove('@alempay/access_token')
            setAuthToken('')
            window.location.href = '/'
        }
    },
)

export const setAuthToken = (token: string) => {
    HTTP.defaults.headers.common.Authorization = token
}
