import { styled, Tabs as MUITabs } from '@mui/material'

export const Tabs = styled(MUITabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        width: 'fit-content',
        borderBottom: '1px solid #C4C4C4',
    },

    '& .MuiButtonBase-root.MuiTab-root, & .MuiButtonBase-root.MuiTab-root.Mui-selected': {
        padding: '0 40px',
        fontSize: '20px',
        textTransform: 'none',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        '& .MuiTabs-flexContainer': {
            margin: '0 auto',
        },
    },
}))
