import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { __DEV__ } from '../../../../constants/env'
import { login } from '../../../../store/reducers/user'

const initialValues = {
    phone: '',
    password: '',
    captcha: '',
}

export type Values = typeof initialValues

export const useForm = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    return useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            phone: Yup.string().required('Введите номер телефона'),
            password: Yup.string().required(t('login.errors.passwordRequired')),
            captcha: __DEV__ ? Yup.mixed() : Yup.string().required('Заполните каптчу!'),
        }),
        onSubmit: ({ password, phone, captcha }) => {
            const phoneNumber = phone.match(/\d/g)?.join('') || ''
            dispatch(login({ phone: phoneNumber, password, captcha }))
        },
    })
}
