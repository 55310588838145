import { FC, useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, Theme, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { FilterAltOutlined } from '@mui/icons-material'
import { CustomButton } from '../CustomButtons'
import {
    Header,
    HeaderTitle,
    TransactionsHistory,
    TransactionBox,
    TransactionItem,
    TransactionLogo,
    TransactionDay,
    TransactionInfo,
    TransactionNum,
} from './style'
import groupBy from 'lodash.groupby'
import InfiniteScroll from 'react-infinite-scroller'
import { useDisclosure } from '../../hooks/useDisclosure'
import { FilterModal } from '../FilterModal'
import {
    TransactionDeposit,
    TransactionWithdrawals,
} from '../../utils/api/routes/transactions/types'
import { LoadingView } from './LoadingView'
import { DetailedInfoModal } from './DetailedInfoModal'

export type Transaction = TransactionDeposit | TransactionWithdrawals

type TransactionsProps = {
    titleText: string
    titleSize?: 'middle' | 'large'
    lengthData?: number
    withFilter?: boolean
    data: Transaction[]
    onFilters?: (x: any) => void
    hasMore?: boolean
    next?: (x: number) => void
    isLoading?: boolean
}

export const Transactions: FC<TransactionsProps> = ({
    titleText,
    titleSize,
    withFilter = true,
    data,
    onFilters,
    hasMore,
    next,
    isLoading,
}) => {
    const { isOpen: isFiltersOpen, open: openFilters, close: closeFilters } = useDisclosure()
    const { t } = useTranslation()
    const isLessDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
    const [transaction, setTransaction] = useState<Transaction | null>(null)

    const historyData = Object.entries(
        groupBy(data, page => {
            const key = page.dt.split(' ')[0]
            return key
        }),
    )

    const getTitleSize = useCallback(() => {
        switch (titleSize) {
            case 'middle':
                return '20px'
            case 'large':
                return isLessDesktop ? '22px' : '28px'
        }
    }, [isLessDesktop, titleSize])

    const renderTransaction = useMemo(() => {
        if (isLoading) {
            return <LoadingView />
        }

        if (!historyData.length) return <Typography>{t('cards.transactions.notFound')}</Typography>

        const createSign = (type: string) => {
            switch (type) {
                case 'deposit':
                    return '+'
                default:
                    return ''
            }
        }

        return historyData.map(([day, transactionsList]) => (
            <TransactionBox key={day}>
                <TransactionDay>{day}</TransactionDay>
                {transactionsList.map(transaction => {
                    const { amount, id, type } = transaction
                    return (
                        <TransactionItem onClick={() => setTransaction(transaction)} key={id}>
                            <TransactionLogo />
                            <div>
                                <TransactionInfo
                                    primary={
                                        type === 'deposit'
                                            ? t('cards.transactions.types.refil')
                                            : transaction.firm
                                    }
                                />
                                {transaction.type === 'withdrawals' && (
                                    <>
                                        <TransactionNum>
                                            {t('cards.transactions.transport.routeNum')}{' '}
                                            {transaction.route}
                                        </TransactionNum>
                                        <br />
                                        <TransactionNum>
                                            {t('cards.transactions.transport.transportNum')}{' '}
                                            {transaction.autonum}
                                        </TransactionNum>
                                    </>
                                )}
                            </div>

                            <TransactionInfo
                                type={type as any}
                                primary={`${createSign(type)}${amount} ₸`}
                            />
                        </TransactionItem>
                    )
                })}
            </TransactionBox>
        ))
    }, [historyData])

    return (
        <>
            {transaction && (
                <DetailedInfoModal transcation={transaction} onClose={() => setTransaction(null)} />
            )}
            {withFilter && (
                <FilterModal
                    onSubmit={filters => {
                        onFilters?.(filters as any)
                        closeFilters()
                    }}
                    open={isFiltersOpen}
                    onClose={closeFilters}
                />
            )}
            <Header>
                <HeaderTitle sx={{ fontSize: getTitleSize() }}>{titleText}</HeaderTitle>

                {withFilter ? (
                    <CustomButton
                        variant="contained"
                        startIcon={<FilterAltOutlined />}
                        onClick={openFilters}
                    >
                        {t('ui.buttons.filter')}
                    </CustomButton>
                ) : null}
            </Header>

            <TransactionsHistory>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={pageParam => next?.(pageParam)}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={<LinearProgress />}
                >
                    {renderTransaction}
                </InfiniteScroll>
            </TransactionsHistory>
        </>
    )
}
