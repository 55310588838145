import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, Theme } from '@mui/material'

import { DocumentTitle } from '../../components/DocumentTitle'
import { Wrapper, Title, Description } from './styles'
import { LoadingView } from './LoadingView'
import { useInstructions } from './utils/useInstructions'
import { List } from './List'

const Instructions: FC = () => {
    const { t } = useTranslation()
    const { isLoading, instructions } = useInstructions()

    const isLessDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <DocumentTitle title={t('titles.instructions')}>
            <Title>{isLessDesktop ? t('instructions.shortTitle') : t('instructions.title')}</Title>
            {isMobile && <Description>{t('instructions.description')}</Description>}

            <Wrapper>{isLoading ? <LoadingView /> : <List instructions={instructions} />}</Wrapper>
        </DocumentTitle>
    )
}

export default Instructions
