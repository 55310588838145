import { FC } from 'react'
import { useTheme } from '@mui/material'
import { LatLngExpression } from 'leaflet'
import { TileLayer, Marker, Popup, MapContainer, Polyline } from 'react-leaflet'

import { GetLocation } from './GetLocation'
import { useRoute } from './utils/useRoute'
import { useBuses } from './utils/useBuses'
import { busMarker, stopMarker, stopMarker_r, userMarker } from './utils/markers'
import { Stops } from './Stops'
import { useCoords } from '../../../hooks/useCoords'
import { Direction } from '../types'
import { useTranslation } from 'react-i18next'

export interface MapProps {
    route: number | string
    direction: Direction
}

export const Map: FC<MapProps> = ({ route, direction }) => {
    const coords = useCoords()
    const currentPosition = (
        coords ? [coords.latitude, coords.longitude] : [0, 0]
    ) as LatLngExpression
    const theme = useTheme()
    const { stops, r_stops, lines, r_lines, isLoading, position } = useRoute(route)
    const { busPositions } = useBuses(route)
    const { t } = useTranslation()

    return (
        <MapContainer
            style={{ width: '100%', zIndex: 1, height: '100vh' }}
            center={[0, 0]}
            zoom={13}
            scrollWheelZoom={false}
        >
            <TileLayer
                attribution='<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=wfd3IaA1KShvPdPZWUiJ8J6npuWAtJZyhKhrEFv8KcKdFTfThGo1njWiLJJBz2gB"
            />
            <Marker icon={userMarker} position={currentPosition}>
                <Popup>{t('map.userGeoPosition')}</Popup>
            </Marker>
            {direction === 'reverse' ? (
                <Stops marker={stopMarker_r} stops={r_stops} />
            ) : (
                <Stops marker={stopMarker} stops={stops} />
            )}

            {busPositions.map(bus => (
                <Marker icon={busMarker} position={[bus.lat, bus.lon]}>
                    <Popup>{bus.num}</Popup>
                </Marker>
            ))}
            {direction === 'reverse' ? (
                <Polyline
                    pathOptions={{ color: theme.palette.secondary.main }}
                    positions={r_lines}
                />
            ) : (
                <Polyline pathOptions={{ color: theme.palette.primary.main }} positions={lines} />
            )}

            <GetLocation coords={position} />
        </MapContainer>
    )
}
