import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../../../hooks/useAppSelector'
import { setRegion } from '../../../../store/reducers/directions'
import { getRegions } from '../../../../utils/api/routes/regions'

export const useRegions = () => {
    const dispatch = useDispatch()
    const { regionNumber } = useAppSelector(state => state.directions)

    const { data, ...query } = useQuery('regions', getRegions, {
        onSuccess(res) {
            if (res.data.length && regionNumber === null) {
                dispatch(setRegion(+res.data[0].number))
            }
        },
    })

    const regions = data?.data || []

    return {
        region: regionNumber || regions[0]?.number || 2,
        regions,
        ...query,
    }
}
