import { Paper, styled } from '@mui/material'

export const CardContainer = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    WebkitFlexWrap: 'wrap',
    MSFlexWrap: 'wrap',
    gap: '40px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
        gap: '20px',
    },
}))

export const CardBlock = styled('div')(({ theme }) => ({
    width: '460px',
    height: '280px',
    [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        maxWidth: '100%',
        height: '230px',
        '&:last-child': {
            height: 'auto',
        },
    },
}))

export const CardActions = styled('div')(({ theme }) => ({
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateAreas: `"add history activate" "block unblock activate"`,
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateAreas: `"add history" "block unblock" "activate activate"`,
        gap: '10px',
    },
}))

export interface CardActionItemProps {
    disabled?: boolean
}

export const CardActionItem = styled(Paper)<CardActionItemProps>(({ theme, disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    paddingLeft: '15px',
    paddingRight: '15px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: '2px solid #FFFFFF',
    borderRadius: '15px',
    boxShadow: '0px 0px 20px rgba(51, 64, 79, 0.05)',
    filter: disabled ? 'grayscale(100%)' : undefined,
    opacity: disabled ? 0.5 : 1,
    transition: 'border 0.2s ease 0s',

    '&:hover': {
        border: '2px solid #BC9BFE',
    },

    [theme.breakpoints.down('sm')]: {
        paddingTop: '25px',
        paddingBottom: '25px',
    },
}))

export const CardActionText = styled('span')(({ theme }) => ({
    fontSize: '17px',
    fontWeight: '500',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
    },
}))
