import { styled } from '@mui/material'
import { AuthButton } from '../styles'

export const LoginButton = styled(AuthButton)({
    background: 'linear-gradient(45deg, #686868 30%, #424242 90%)',
    boxShadow: 'none',
    '&:hover, &:active': {
        boxShadow: 'none',
    },
})
