import { styled } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
    marginTop: '30px',
    '& span': {
        fontSize: '18px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
            lineHeight: '16px',
        },
    },
}))
