import { styled, Button, IconButton } from '@mui/material'

export const MyButton = styled(Button)(({ theme }) => ({
    borderRadius: '5px',
    boxShadow: 'none',

    fontSize: '16px',
    textTransform: 'none',

    color: '#FFF',

    '&:hover': {
        boxShadow: 'none',
    },
}))

export const MyIconButton = styled(IconButton)(({ theme }) => ({
    width: '40px',
    height: '40px',

    padding: '10px',

    borderRadius: '5px',
    boxShadow: 'none',

    backgroundColor: '#BC9BFE',

    color: '#FFF',

    '&:hover': {
        boxShadow: 'none',
        backgroundColor: '#BD3DF4',
    },
}))
