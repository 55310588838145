import { useQuery } from 'react-query'
import { getInstructions } from '../../../utils/api/routes/instructions'

export const useInstructions = () => {
    const { data, ...query } = useQuery('instructions', getInstructions)

    return {
        instructions: data || [],
        ...query,
    }
}
