import { FC } from 'react'

import { MyButton, MyIconButton } from './style'
import { ButtonProps } from '@mui/material'

export const CustomButton: FC<ButtonProps> = ({
    variant,
    size,
    startIcon,
    endIcon,
    disabled,
    color,
    children,
    onClick,
}) => {
    return (
        <MyButton
            variant={variant}
            startIcon={startIcon}
            endIcon={endIcon}
            size={size}
            disabled={disabled}
            color={color}
            onClick={onClick}
        >
            {children}
        </MyButton>
    )
}

export const CustomIconButton: FC<ButtonProps> = ({ size, children, disabled, color, onClick }) => {
    return (
        <MyIconButton size={size} disabled={disabled} color={color} onClick={onClick}>
            {children}
        </MyIconButton>
    )
}
