import { useEffect, useState } from 'react'

export const useCoords = () => {
    const [state, setState] = useState<GeolocationCoordinates | null>(null)

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            setState(coords)
        })
    }, [])

    return state
}
