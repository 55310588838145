import React from 'react'
import { Avatar, Box, Theme, Typography, useMediaQuery } from '@mui/material'
import { Logout, Menu } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Drawer, Divider, BurgerButton } from './styles'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { DRAWER_WIDTH } from './utils/constants'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useLinks } from './utils/useLinks'
import { MenuItem } from './MenuItem'
import { logOut } from '../../../store/reducers/user'

export const Sidebar: React.FC = () => {
    const { isOpen, open, close } = useDisclosure()
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const user = useAppSelector(state => state.user)
    const links = useLinks()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <>
            <Box display={{ sm: 'none' }} pl={3} pt={1} pb="0">
                <BurgerButton onClick={open}>
                    <Menu fontSize="large" color="primary" />
                </BurgerButton>
            </Box>
            <Box
                component="nav"
                sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    anchor="left"
                    variant={isMobile ? 'temporary' : 'permanent'}
                    open={isOpen}
                    onClose={close}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <Box
                        display="flex"
                        height="100vh"
                        justifyContent="space-around"
                        flexDirection="column"
                    >
                        <Box>
                            <Box display="flex" gap="15px" alignItems="center">
                                <Avatar sx={{ width: 42, height: 42 }} alt="User Avatar" src="" />
                                <Typography fontSize={20} fontWeight={600}>
                                    {user?.profile?.phone}
                                </Typography>
                            </Box>
                            <Divider />
                        </Box>
                        <Box>
                            {links.map(link => (
                                <MenuItem {...link} />
                            ))}
                        </Box>
                        <Box>
                            <Divider />
                            <MenuItem
                                label={t('sidebar.exit')}
                                onClick={() => dispatch(logOut())}
                                icon={Logout}
                            />
                        </Box>
                    </Box>
                </Drawer>
            </Box>
        </>
    )
}
