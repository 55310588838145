import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    width: '100%',
    marginBottom: 20,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 10px rgba(166, 166, 166, 0.25)',
    borderRadius: '10px',
    padding: '15px',
})

export const Link = styled(NavLink)({
    color: 'black',
    textDecoration: 'none',
    width: '100%',
})

export const InfoWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
})
