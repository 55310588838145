import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import ReCAPTCHA from 'react-google-recaptcha'
import { InputAdornment, IconButton, OutlinedInputProps, Box, Link } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { __DEV__ } from '../../../constants/env'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { AuthFormInput, Form, Title } from '../styles'
import { LoginButton } from './styles'
import { DocumentTitle } from '../../../components/DocumentTitle'
import { useForm } from './utils/useForm'

const AuthMaskedInput: FC<any> = props => {
    return (
        <InputMask {...props}>
            {(inputProps: InputMaskProps & OutlinedInputProps) => <AuthFormInput {...inputProps} />}
        </InputMask>
    )
}

const Login: FC = () => {
    const { isOpen, toggle: toggleVisibility } = useDisclosure()
    const { t } = useTranslation()
    const { handleChange, setFieldValue, handleSubmit, values, errors, touched } = useForm()

    return (
        <DocumentTitle title={t('titles.login')}>
            <Form onSubmit={handleSubmit}>
                <Title>{t('login.title')}</Title>
                <AuthMaskedInput
                    name="phone"
                    placeholder={t('login.inputs.cardNumber')}
                    mask="+7(999)-999-99-99"
                    value={values.phone}
                    helperText="Введите верный номер телефона!"
                    onChange={handleChange}
                    error={touched.phone && Boolean(errors.phone)}
                />

                <AuthFormInput
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    placeholder={t('login.inputs.password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleVisibility}
                                edge="end"
                            >
                                {isOpen ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                />
                <Box display="flex" justifyContent="center">
                    <ReCAPTCHA
                        sitekey="6LfoeSohAAAAAHsZBczEJwvDtiQFmO4KvkZf1llS"
                        onChange={token => setFieldValue('captcha', token || '')}
                    />
                </Box>
                <LoginButton disableRipple fullWidth variant="contained" type="submit">
                    {t('login.actions.signIn')}
                </LoginButton>
                <Link href="/register">{t('login.actions.noAccount')}</Link>
            </Form>
        </DocumentTitle>
    )
}

export default Login
