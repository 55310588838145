// @ts-ignore
import { ReactComponent as Logo } from '../../assets/logo/logo.svg'
import { Box, Grid, styled } from '@mui/material'

export interface CardWrapperProps {
    disabled?: boolean
    active?: boolean
}

export const CardWrapper = styled('div')<CardWrapperProps>(({ active, disabled }) => ({
    width: '100%',
    height: '100%',
    border: active ? '2px solid #3498DB' : undefined,
    cursor: disabled ? 'not-allowed' : 'pointer',
    borderRadius: '30px',
    filter: disabled ? 'grayscale(100%)' : undefined,
    overflow: 'hidden',
}))

export const Card = styled('div')({
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(179, 49, 231)',
    background:
        'linear-gradient(137deg, rgba(179,49,231,1) 18%, rgba(171,105,241,1) 23%, rgba(171,105,241,1) 40%, rgba(179,49,231,1) 68%, rgba(190,95,228,1) 80%, rgba(249,173,167,1) 90%)',
})

export const CardBackground = styled('div')({
    width: '100%',
    height: '100%',
    WebkitBorderRadius: '30px',
    MozBorderRadius: '30px',
    KhtmlBorderRadius: '30px',
    backdropFilter: 'blur(40px)',
})

export const CardContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    paddingLeft: '35px',
    paddingRight: '20px',
    paddingTop: '20px',
    paddingBottom: '20px',

    [theme.breakpoints.down('sm')]: {
        paddingLeft: '30px',
        paddingRight: '20px',
        paddingTop: '25px',
        paddingBottom: '25px',
    },
}))

export const CardRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const CardItem = styled('div')(({ theme }) => ({
    color: '#FFFFFF',

    fontWeight: '500',
    fontSize: '20px',

    '& span': {
        fontSize: '18px',
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',

        '& span': {
            fontSize: '14px',
        },
    },
}))

export const CardItemValue = styled(CardItem)(({ theme }) => ({
    fontSize: '28px',

    [theme.breakpoints.down('sm')]: {
        fontSize: '25px',
    },
}))

export const CardItemNumber = styled(CardItem)({
    fontWeight: '400',
})

export const CardBlock = styled('div')(({ theme }) => ({
    width: '460px',
    height: '280px',

    [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        maxWidth: '100%',

        height: '230px',

        '&:last-child': {
            height: 'auto',
        },
    },
}))

export const CardLogo = styled(Logo)(({ theme }) => ({
    maxWidth: '150px',
    '&, & .background': {
        fill: '#FFFFFF',
    },
    '& .pay': {
        fill: '#C545EC',
    },

    [theme.breakpoints.down('sm')]: {
        maxWidth: '150px',
    },
}))
