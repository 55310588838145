import { useQuery } from 'react-query'

import { useAppSelector } from '../../../../hooks/useAppSelector'
import { getRoutesByRegion } from '../../../../utils/api/routes/regions'

export const useRoutesByRegion = () => {
    const { regionNumber } = useAppSelector(state => state.directions)

    const { data, ...query } = useQuery(
        ['buses', regionNumber],
        () => getRoutesByRegion(regionNumber as number),
        {
            refetchOnMount: 'always',
            enabled: regionNumber !== null,
        },
    )

    return {
        routes: data?.data?.routes || [],
        ...query,
    }
}
