import { FC, useEffect, ReactNode } from 'react'

type DocumentTitleProps = {
    children?: ReactNode
    title: string
}

export const DocumentTitle: FC<DocumentTitleProps> = ({ children, title }) => {
    useEffect(() => {
        document.title = title
    }, [title])

    return <>{children}</>
}
