import React, { FC } from 'react'
import {
    Modal,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Transaction } from '..'
import { useTranslation } from 'react-i18next'

interface DetailedInfoModalProps {
    transcation: Transaction
    onClose: () => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
}

export const DetailedInfoModal: FC<DetailedInfoModalProps> = ({ transcation, onClose }) => {
    const { t } = useTranslation()
    return (
        <Modal component="div" open onClose={onClose}>
            <Box sx={style}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">ID</TableCell>
                                <TableCell align="center">{transcation.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">{t('details.modal.date')}</TableCell>
                                <TableCell align="center">{transcation.dt}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">{t('details.modal.amount')}</TableCell>
                                <TableCell align="center">{transcation.amount}</TableCell>
                            </TableRow>
                            {transcation.type === 'withdrawals' && (
                                <>
                                    <TableRow>
                                        <TableCell align="left">
                                            {t('details.modal.transportNum')}
                                        </TableCell>
                                        <TableCell align="center">{transcation.autonum}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            {t('details.modal.route')}
                                        </TableCell>
                                        <TableCell align="center">{transcation.route}</TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    )
}
