import React from 'react'
import { Page, PageContainer } from './styles'
import { Outlet } from 'react-router-dom'

export const PageWrapper = () => {
    return (
        <Page>
            <PageContainer>
                <Outlet />
            </PageContainer>
        </Page>
    )
}
