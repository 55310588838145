import React, { MouseEvent } from 'react'
import { Modal, Typography, ModalProps } from '@mui/material'
import { Centered, SubmitButton } from './styles'
import { useTranslation } from 'react-i18next'

interface AdCardModalProps extends Omit<ModalProps, 'children' | 'onSubmit'> {
    onSubmit?: (event: MouseEvent) => void
}

export const AddCardModal: React.FC<AdCardModalProps> = ({ onSubmit, ...props }) => {
    const { t } = useTranslation()
    return (
        <Modal {...props}>
            <Centered>
                <Typography id="modal-modal-title" variant="h5" component="h5" fontWeight={600}>
                    {t('registration.actions.addCard')}
                </Typography>
                <Typography
                    id="modal-modal-description"
                    mt={2}
                    fontSize={14}
                    textAlign="left"
                    color="#A9A9A9"
                >
                    {t('registration.text')}
                </Typography>
                <SubmitButton onClick={onSubmit} size="small">
                    {t('registration.actions.accept')}
                </SubmitButton>
            </Centered>
        </Modal>
    )
}
