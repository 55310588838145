import { FC } from 'react'
import { Marker, MarkerProps, Popup } from 'react-leaflet'
import { StopData } from '../../../../utils/api/routes/regions'

interface StopsProps {
    stops: StopData[]
    marker: MarkerProps['icon']
}

export const Stops: FC<StopsProps> = ({ stops, marker }) => (
    <>
        {stops.map(stop => (
            <Marker icon={marker} position={stop.position}>
                <Popup>{stop.title}</Popup>
            </Marker>
        ))}
    </>
)
