import React from 'react'
import { FileDownload } from '@mui/icons-material'

import { Instruction } from '../../../utils/api/routes/instructions'
import { InstructionsItem, InstructionsButton } from '../styles'

interface ListProps {
    instructions: Instruction[]
}

export const List: React.FC<ListProps> = ({ instructions }) => {
    return (
        <>
            {instructions.map(instruction => (
                <InstructionsItem key={instruction.id}>
                    <span>{instruction.title}</span>

                    <InstructionsButton variant="button" href={instruction.src_url} target="_blank">
                        <FileDownload fontSize="large" />
                    </InstructionsButton>
                </InstructionsItem>
            ))}
        </>
    )
}
