import { HTTP } from '../..'
import i18next from 'i18next'

export interface Region {
    readonly id: number
    number: string
    title: string
}

export const getRegions = () => {
    const lang = i18next.language
    return HTTP.get<Region[]>('/service-info/regions/', {
        params: { lang },
    })
}

export interface Route {
    num: string
    name: string
}

export const getRoutesByRegion = (region: number) => {
    const lang = i18next.language
    return HTTP.get<{ routes: Route[] }>('/service-info/routes/', {
        params: { method: 'GetRoutesList', region, lang },
    })
}

export type XY = [number, number]

export interface StopData {
    title: string
    position: XY
}

export interface GetRouteResponse {
    number: string
    type: string
    stops: StopData[]
    lines: XY[]
    r_lines: XY[]
    r_stops: StopData[]
}

export const getRoute = (region: number, route: number | string) => {
    const lang = i18next.language
    return HTTP.get<GetRouteResponse>('/service-info/routes/', {
        params: { method: 'GetRoute', region, route, lang },
    })
}
