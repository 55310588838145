import { ReactNode, useState, FC } from 'react'
import { Tab, Box } from '@mui/material'

import { Tabs } from './style'

type AppTabsProps = {
    labels: string[]
    children: ReactNode[]
}

export const BaseTabs: FC<AppTabsProps> = ({ labels, children }) => {
    const [value, setValue] = useState(0)

    return (
        <Box width="100%">
            <Tabs value={value} onChange={(_, value) => setValue(value)}>
                {labels.map(label => (
                    <Tab label={label} key={label} />
                ))}
            </Tabs>
            {children.map((el, index) => value === index && el)}
        </Box>
    )
}
