import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    CardBlock,
    Card as StyledCard,
    CardItem,
    CardBackground,
    CardContent,
    CardItemNumber,
    CardItemValue,
    CardLogo,
    CardRow,
    CardWrapper,
    CardWrapperProps,
} from './styles'

export interface CardMetaData {
    card: string
    balance: string
}

interface CardProps extends CardMetaData, Omit<CardWrapperProps, 'disabled'> {
    onClick?: (meta: CardMetaData) => void
}

export const Card: React.FC<CardProps> = ({ card, onClick, active, balance }) => {
    const { t } = useTranslation()

    return (
        <CardBlock onClick={() => onClick?.({ card, balance })}>
            <CardWrapper active={active}>
                <StyledCard>
                    <CardBackground>
                        <CardContent>
                            <CardRow>
                                <CardItem>
                                    <span>{t('cards.account')}:</span>
                                    <CardItemValue>{balance} &#8376;</CardItemValue>
                                </CardItem>
                                <CardLogo />
                            </CardRow>
                            <CardRow>
                                <CardItemNumber>{card}</CardItemNumber>
                            </CardRow>
                        </CardContent>
                    </CardBackground>
                </StyledCard>
            </CardWrapper>
        </CardBlock>
    )
}
