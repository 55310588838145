import { createSlice } from '@reduxjs/toolkit'
import { State } from './types'

const initialState: State = {
    testData: [
        {
            date: '01.04.2022',
            transactionsList: [
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: 'Тикетон',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
                {
                    logo: 'img',
                    type: 'refill',
                    info: {
                        title: 'Пополнение карты',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: 'none',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: 'null',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: '1',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: '2',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: '3',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: '4',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
            ],
        },
        {
            date: '02.04.2022',
            transactionsList: [
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: 'Тикетон',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
            ],
        },
        {
            date: '03.04.2022',
            transactionsList: [
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: 'Тикетон',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
            ],
        },
        {
            date: '04.04.2022',
            transactionsList: [
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: 'Тикетон',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
            ],
        },
        {
            date: '05.04.2022',
            transactionsList: [
                {
                    logo: 'img',
                    type: 'other',
                    info: {
                        title: 'Тикетон',
                        number: '11111111111111',
                    },
                    amount: '1230 ₸',
                },
            ],
        },
    ],
}

const transactionReducer = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setTransitions: state => {
            state.testData = [...state.testData]
        },
    },
})

export const { setTransitions } = transactionReducer.actions
export default transactionReducer.reducer
