import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { XY } from '../../../utils/api/routes/regions'

interface GeoLocationProps {
    coords?: XY
}

export const GetLocation: React.FC<GeoLocationProps> = ({ coords }) => {
    const map = useMap()

    useEffect(() => {
        if (!coords) return
        const [latitude, longitude] = coords

        map.setView([latitude, longitude])
    }, [coords])

    return null
}
