import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/system'

import logo from '../../assets/logo/logo.svg'
import { Logo, Wrapper, Root } from './styles'

export const AuthPageWrapper: FC = () => {
    return (
        <Root>
            <Box>
                <a href="https://www.alempay.kz/" target="_blank">
                    <Logo src={logo} alt="logo" />
                </a>
                <Wrapper elevation={0}>
                    <Outlet />
                </Wrapper>
            </Box>
        </Root>
    )
}
