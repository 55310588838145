import { Skeleton } from '@mui/lab'
import React from 'react'
import { InstructionsItem } from '../styles'

export const LoadingView: React.FC = () => {
    return (
        <>
            {Array.from({ length: 3 }).map((_, index) => (
                <InstructionsItem key={index}>
                    <Skeleton variant="text" animation="wave" width="50%" />
                    <Skeleton variant="text" animation="wave" width={70} height={50} />
                </InstructionsItem>
            ))}
        </>
    )
}
