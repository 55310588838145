import Select from 'react-select'
import { Title } from './styles'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentTitle } from '../../components/DocumentTitle'

interface LanguageItem {
    value: string
    label: string
}

const Settings: React.FC = () => {
    const { t, i18n } = useTranslation()

    const languages = useMemo<LanguageItem[]>(
        () => [
            {
                value: 'kz',
                label: '🇰🇿 Қазақша',
            },
            {
                value: 'ru',
                label: '🇷🇺 Русский',
            },
        ],
        [],
    )

    const getLanguage = useCallback(
        (value: string) => {
            const lang = languages.find(lang => lang.value === value)
            return lang
        },
        [i18n, languages],
    )

    return (
        <DocumentTitle title={t('titles.settings')}>
            <Title>{t('settings.langChange')}</Title>
            <Select
                isSearchable={false}
                value={getLanguage(i18n.language)}
                options={languages}
                onChange={props => {
                    const { value } = props as LanguageItem
                    i18n.changeLanguage(value)
                    window.location.reload()
                }}
            />
        </DocumentTitle>
    )
}

export default Settings
