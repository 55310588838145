import React, { MouseEvent, useState } from 'react'
import { Modal, Typography, ModalProps, Stack } from '@mui/material'
import { Centered, SubmitButton } from '../AddCardModal/styles'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface FilterModalProps extends Omit<ModalProps, 'children' | 'onSubmit'> {
    onSubmit?: (data: { dt_begin: string; dt_end: string }) => void
}

export const FilterModal: React.FC<FilterModalProps> = ({ onSubmit, ...props }) => {
    const [beginDate, setBeginDate] = useState<null | Date>(null)
    const [endDate, setEndDate] = useState<null | Date>(null)
    const { t } = useTranslation()

    const handleSubmit = (e: MouseEvent) => {
        onSubmit?.({
            dt_begin: moment(beginDate).format('DD.MM.YYYY'),
            dt_end: moment(endDate).format('DD.MM.YYYY'),
        })
    }

    return (
        <Modal
            {...props}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Centered>
                <Typography
                    id="modal-modal-title"
                    sx={{ mb: '20px' }}
                    variant="h5"
                    component="h5"
                    fontWeight={600}
                >
                    {t('ui.buttons.filter')}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack alignItems="center" direction="row" gap="30px">
                        <MobileDatePicker
                            label={t('ui.tags.startDate')}
                            inputFormat="dd.MM.yyyy"
                            value={beginDate}
                            onChange={setBeginDate}
                            renderInput={params => <TextField {...params} />}
                        />
                        <MobileDatePicker
                            label={t('ui.tags.finishDate')}
                            inputFormat="dd.MM.yyyy"
                            value={endDate}
                            onChange={setEndDate}
                            renderInput={params => <TextField {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>

                <SubmitButton onClick={handleSubmit} size="small">
                    {t('ui.actions.submit')}
                </SubmitButton>
            </Centered>
        </Modal>
    )
}
