import { Drawer as MUIDrawer, Divider as MUIDivider, Button } from '@mui/material'
import { styled } from '@mui/system'

import { DRAWER_WIDTH } from './utils/constants'

export const BurgerButton = styled(Button)({
    zIndex: '100',
    padding: '10px 0px',
    boxShadow: '0px 0px 50px rgba(51, 64, 79, 0.15)',
    borderRadius: '8px',
})

export const Drawer = styled(MUIDrawer)(props => ({
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        padding: '25px',
        boxSizing: 'border-box',
        width: DRAWER_WIDTH,
        backgroundColor: '#2F1E53',
        color: '#FFFFFF',
        fontSize: '20px',
        fontFamily: 'Inter',
    },
}))

export const Divider = styled(MUIDivider)({
    backgroundColor: '#725CA0',
    margin: '20px 0',
})
