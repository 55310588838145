import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OutlinedInput, Box } from '@mui/material'
import { Search } from '@mui/icons-material/'

import { Container } from './styles'
import { useDebounce } from '../../../hooks/useDebounce'
import { LoadingView } from './LoadingView'
import { useRoutesByRegion } from './utils/useRoutesByRegion'
import { Direction } from './Direction'
import { RegionSelect } from '../RegionSelect'

export const Buses: FC = () => {
    const { t } = useTranslation()
    const [search, setSearch] = useState('')

    const debouncedSearch = useDebounce(search, 300)
    const { routes, isLoading } = useRoutesByRegion()

    if (isLoading) {
        return (
            <Container>
                <LoadingView />
            </Container>
        )
    }

    return (
        <Container>
            <Box gap="10px" display="flex">
                <OutlinedInput
                    fullWidth
                    sx={{ mb: '30px' }}
                    startAdornment={<Search sx={{ mr: 1 }} />}
                    inputProps={{ 'aria-label': 'search' }}
                    value={search}
                    onChange={event => setSearch(event.target.value)}
                    placeholder={t('directions.search')}
                    type="number"
                />
                <RegionSelect />
            </Box>
            {routes
                .filter(route =>
                    debouncedSearch ? route.num.toLowerCase().includes(search.toLowerCase()) : true,
                )
                .map(route => (
                    <Direction key={route.num} {...route} />
                ))}
        </Container>
    )
}
