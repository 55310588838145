import { icon } from 'leaflet'

import mapMarker from '../../../../assets/icons/mapMarker.svg'
import mapMarker_r from '../../../../assets/icons/mapMarker_r.svg'
import focusMarker from '../../../../assets/icons/focus.png'
import busMarkerIcon from '../../../../assets/icons/busMarker.svg'

export const stopMarker = icon({
    iconUrl: mapMarker,
    iconSize: [32, 32],
})

export const stopMarker_r = icon({
    iconUrl: mapMarker_r,
    iconSize: [32, 32],
})

export const userMarker = icon({
    iconUrl: focusMarker,
    iconSize: [32, 32],
})

export const busMarker = icon({
    iconUrl: busMarkerIcon,
    iconSize: [32, 32],
})
