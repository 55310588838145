import { Skeleton, Avatar } from '@mui/material'
import { TransactionBox, TransactionItem, TransactionInfo } from '../style'

export const LoadingView: React.FC = () => {
    return (
        <TransactionBox>
            {Array.from({ length: 3 }).map((_, index) => (
                <TransactionItem key={index}>
                    <Skeleton variant="circular" animation="wave" sx={{ marginRight: '15px' }}>
                        <Avatar />
                    </Skeleton>

                    <TransactionInfo
                        primary={<Skeleton variant="text" width="70%" animation="wave" />}
                        secondary={<Skeleton variant="text" animation="wave" width="35%" />}
                    />

                    <TransactionInfo
                        primary={
                            <Skeleton variant="text" animation="wave" width="50px" height="25px" />
                        }
                    />
                </TransactionItem>
            ))}
        </TransactionBox>
    )
}
