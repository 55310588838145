import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch } from 'react-redux'

import { setRegion } from '../../../store/reducers/directions'
import { useRegions } from './utils/useRegions'
import { useTranslation } from 'react-i18next'

export const RegionSelect: React.FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { region, regions } = useRegions()

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('ui.titles.region')}</InputLabel>
            <Select
                onChange={event => dispatch(setRegion(+event.target.value))}
                displayEmpty
                label={t('ui.titles.region')}
                labelId="demo-simple-select-label"
                value={region}
                variant="outlined"
                fullWidth
            >
                {regions.map(region => (
                    <MenuItem key={region.id} value={region.number}>
                        {region.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
