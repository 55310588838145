import { FC } from 'react'
import { DirectionsBus } from '@mui/icons-material'

import { Route } from '../../../../utils/api/routes/regions'
import { Container, Link, InfoWrapper } from './styles'
import { useTranslation } from 'react-i18next'

export const Direction: FC<Route> = ({ num, name }) => {
    const { t } = useTranslation()
    return (
        <Container>
            <Link to={`./${num}`}>
                <InfoWrapper>
                    <DirectionsBus />
                    <span>
                        {t('map.route')} {num} - {name}
                    </span>
                </InfoWrapper>
            </Link>
        </Container>
    )
}
