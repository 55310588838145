import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BaseTabs } from '../../components/BaseTabs'
import { MyCard } from './MyCard'
import { DocumentTitle } from '../../components/DocumentTitle'

import { Other } from './Other'

const Cards: FC = () => {
    const { t } = useTranslation()

    return (
        <DocumentTitle title={t('titles.cards')}>
            <BaseTabs labels={[t('cards.tabs.myCard'), t('cards.tabs.otherCards')]}>
                <MyCard />
                <Other />
            </BaseTabs>
        </DocumentTitle>
    )
}

export default Cards
