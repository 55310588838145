import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { Map } from './Map'
import { Panel } from './Panel'
import { Direction } from './types'

const Route: FC = () => {
    const { route } = useParams() as { route: string }
    const [direction, setDirection] = useState<Direction>('straight')

    return (
        <Box position="relative" m={-3}>
            <Panel direction={direction} setDirection={setDirection} route={route} />
            <Map direction={direction} route={route} />
        </Box>
    )
}

export default Route
