import React from 'react'
import { Modal, Typography, ModalProps } from '@mui/material'

import { Centered, SubmitButton } from '../AddCardModal/styles'

interface AdCardModalProps extends Omit<ModalProps, 'children' | 'onSubmit'> {
    title: string
    description: string
    sub?: string
    button?: string
}

export const MockModal: React.FC<AdCardModalProps> = props => {
    return (
        <Modal {...props}>
            <Centered>
                <Typography variant="h5" fontWeight={600}>
                    {props.title}
                </Typography>
                <Typography mt={2} fontSize={14} textAlign="left" color="#A9A9A9">
                    {props.description}
                </Typography>
                {!!props.sub && (
                    <Typography mt={2} fontSize={14} textAlign="left" color="#A9A9A9">
                        {props.sub}
                    </Typography>
                )}
                <SubmitButton onClick={() => props.onClose?.({}, 'backdropClick')} size="small">
                    {props.button || 'Хорошо'}
                </SubmitButton>
            </Centered>
        </Modal>
    )
}
