import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, Theme, Box } from '@mui/material'

import { Transactions } from '../../components/Transactions'
import { DocumentTitle } from '../../components/DocumentTitle'
import { useAppSelector } from '../../hooks/useAppSelector'
import { RegionSelect } from '../Routes/RegionSelect'
import { useInfiniteTransactions } from './utils/useInfiniteTransactions'

const Details: FC = () => {
    const { t } = useTranslation()
    const isLessDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
    const cardInfo = useAppSelector(state => state.user.card)
    const [filters, setFilters] = useState({})
    const { regionNumber } = useAppSelector(state => state.directions)
    const { transactions, hasNextPage, isLoading, fetchNextPage } = useInfiniteTransactions({
        card: cardInfo?.card as string,
        region: `${regionNumber}`,
        ...filters,
    })

    return (
        <DocumentTitle title={t('titles.details')}>
            <Box marginBottom="10px">
                <RegionSelect />
            </Box>
            <Transactions
                hasMore={hasNextPage && !isLoading}
                data={transactions}
                next={() => fetchNextPage()}
                titleText={isLessDesktop ? t('details.shortTitle') : t('details.title')}
                onFilters={setFilters}
                titleSize={'large'}
                isLoading={isLoading}
            />
        </DocumentTitle>
    )
}

export default Details
