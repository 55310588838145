import { FC } from 'react'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DocumentTitle } from '../../../components/DocumentTitle'
import { useNavigate } from 'react-router-dom'

const Registration: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <DocumentTitle title={t('titles.registration')}>
            <Typography variant="h5" fontWeight={600}>
                {t('registration.actions.addCard')}
            </Typography>
            <Typography
                mt={2}
                mb={1}
                fontSize={15}
                fontWeight={500}
                textAlign="center"
                color="#A9A9A9"
            >
                {t('registration.text')}
            </Typography>
            <Button onClick={() => navigate('/')}>{t('registration.actions.signUp')}</Button>
        </DocumentTitle>
    )
}

export default Registration
