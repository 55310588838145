import React from 'react'
import { Stack } from '@mui/material'

import { Card, CardMetaData } from '../../../components/Card'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { setActiveCard } from '../../../store/reducers/user'
import { useDispatch } from 'react-redux'

export const Other: React.FC = () => {
    const { card, cards } = useAppSelector(state => state.user)
    const dispatch = useDispatch()

    const handleCardClick = (payload: CardMetaData) => {
        dispatch(setActiveCard(payload))
    }

    return (
        <Stack marginTop="20px" display="flex" direction="column" gap="30px">
            {cards.map(c => (
                <Card onClick={handleCardClick} {...c} active={card?.card === c.card} />
            ))}
        </Stack>
    )
}
