import { FC } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'

import { Link } from '../utils/useLinks'

interface MenuItemProps extends Omit<Link, 'path'> {
    path?: string
    onClick?: () => void
}

export const MenuItem: FC<MenuItemProps> = ({ icon: Icon, path, label, onClick }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const isActive = pathname === path

    const handleClick = () => {
        if (onClick) onClick()
        if (path) navigate(path)
    }

    return (
        <Box
            borderRadius="6px"
            color="white"
            py={1}
            px={1.5}
            my={1.5}
            sx={{ backgroundColor: isActive ? '#3F296D' : 'inherit', cursor: 'pointer' }}
            onClick={handleClick}
            display="flex"
            alignItems="center"
            gap="12.5px"
        >
            <Icon fontSize="medium" />
            <Typography fontSize="18px" fontWeight={500}>
                {label}
            </Typography>
        </Box>
    )
}
