import React, { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'

import { Sidebar } from './Sidebar'

interface LayoutProps {
    children: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children }) => (
    <Box display={{ sm: 'flex' }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {children}
        </Box>
    </Box>
)
