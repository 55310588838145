import { FC } from 'react'
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineSeparator,
} from '@mui/lab'

import { StopData } from '../../../../utils/api/routes/regions'
import { Item } from './styles'

interface BusRouteProps {
    stops: StopData[]
    reverse?: boolean
}

export const BusRoute: FC<BusRouteProps> = ({ stops, reverse }) => {
    return (
        <Timeline position="right">
            {stops.map(item => (
                <Item>
                    <TimelineSeparator>
                        <TimelineDot color={reverse ? 'secondary' : 'primary'} />
                        <TimelineConnector
                            sx={{ bgcolor: reverse ? 'secondary.main' : 'primary.main' }}
                        />
                    </TimelineSeparator>
                    <TimelineContent>{item.title}</TimelineContent>
                </Item>
            ))}
        </Timeline>
    )
}
